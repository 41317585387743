import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Auth from '../views/login/Auth.vue'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Auth,
    beforeEnter(to, from, next) {
      if(store.getters.getAccessToken) {
        next("/school");
      }
      next();
    }
  },
  {
    path: '/',
    name: 'Home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Home,
    /*children: [
      {
        path: 'control',
        name: 'Control',
        component: () => import('../views/control/Control.vue'),
      }
    ]*/
  },
  {
    path: '/school',
    component: () => import('../views/school/SchoolLayout.vue'),
    children: [
      {
        path: '',
        component: () => import('../views/school/RequestList.vue'),
      },
      {
        path: 'request/:id',
        component: () => import('../views/school/RequestView.vue'),
      }
    ],
    beforeEnter(to, from, next) {
      store.dispatch('changeBaseUrl');
      next();
    }
  }
]

const router = new VueRouter({
  routes
})

router.beforeEach((to, from, next) => {
  if(to.name != 'Login' && !store.getters.getAccessToken) {
    next("/login");
  }
  next();
});

export default router
