import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import config from "./config"
import router from './router'

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		accessToken: localStorage.getItem('accessToken'),
		user: null,
		unresolvedRequestCout: 0,
		organization: null,
		alerts:[],
		language: localStorage.getItem('language') || 'ru',
		currentRole: 'school'
	},

	mutations: {
		login(state, response) {
			state.accessToken = response.headers.authorization;
			state.user = response.data.data.user; // TODO
			localStorage.setItem('accessToken', state.accessToken);
			axios.defaults.headers.common['X-Auth'] = state.accessToken;
		},
		setLanguage(state, lang) {
			localStorage.setItem('language', lang);
			state.language = lang;
		},
		setCurrentRole(state, role) {
			state.currentRole = role;
		},
		alert(state, {alert, adding}) {
            adding ? state.alerts.push(alert)
				: state.alerts.shift()
		},
		logout(state) {
			state.accessToken = '';
			state.user = '';
			localStorage.removeItem('accessToken');
			delete axios.defaults.headers.common['X-Auth'];
		},
		setUnresolvedRequestCout(state, count) {
			state.unresolvedRequestCout = count;
		}
	},

	actions: {
		changeBaseUrl({getters, state}) {
			axios.defaults.baseURL = getters.getBaseUrl+(state.accessToken ? '/'+state.currentRole+'/'+state.language : '');
			
		},
		alertAdd({commit}, alert) {
			commit('alert', { alert, adding: true});
			setTimeout(() => commit('alert', { alert, adding: false }), 5000);
		},
		login({commit}, response) {
			return new Promise((resolve) => {
				commit('login', response);
				resolve();
			})
		},
		async getUnresolvedRequestCout({commit}) {
			await axios.get('/request/count').then((response) => {
				if(response.data.status == 'success') {
					commit('setUnresolvedRequestCout', +response.data.data.counter);
				}
			});
		},
		logout({commit, dispatch}){
			commit('logout');
			dispatch('changeBaseUrl');
			router.replace('/login');
		}
	},

	getters: {
		getBaseUrl() {
			return config.API_URL+config.API_VERSION;
		},
		getAccessToken(state) {
			return state.accessToken;
		},
		getCurrentRole(state) {
			return state.currentRole;
		},
		getLanguage(state) {
			return state.language;
		},
		getUnresolvedRequestCout(state) {
			return state.unresolvedRequestCout;
		}
	}

});