import axios from 'axios'
import store from './store'

store.dispatch('changeBaseUrl');

if (store.getters.getAccessToken) {
	axios.defaults.headers.common['X-Auth'] = store.getters.getAccessToken
}

axios.interceptors.response.use((response) => {
	return response;
}, function (error) {
	if (error.response.status === 401) {
		store.dispatch('logout').then(() => {
			let errorText = error.response.data.message;
			if(error.response.data.code == 10) {
				errorText = "Чтобы получить доступ к данной странице Вам необходимо пройти авторизацию.";
			} else if(error.response.data.code == 11) {
				errorText = "Токен авторизации устарел. Заново пройдите авторизацию.";
			} else if(error.response.data.code == 12) {
				errorText = "Неправильный токен авторизации. Заново пройдите авторизацию.";
			}
			store.dispatch('alertAdd', {text: errorText, type: 'error'});

			store.dispatch('logout');
		});
	} else if(error.response.status === 400) {
		store.dispatch('alertAdd', {text: error.response.data.message, type:'error'});
	} else if(error.response.status === 500) {
		store.dispatch('alertAdd', {text: error.response.data.message, type:'error'});
	}
	return Promise.reject(error);
});