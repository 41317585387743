import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import axios from 'axios'
import './axios.config.js'
import router from './router'
import store from './store'

Vue.config.productionTip = true
Vue.prototype.$http = axios;

new Vue({
	vuetify,
	router,
	store,
	render: h => h(App)
}).$mount('#app')
